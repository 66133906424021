<template>
  <v-col class="mx-auto" style="max-width: 600px;">
    <h3>Reporting</h3>
    <GoogleSheetGenerate
      title="Ingredients"
      :loading="loadingReportIngredients"
      :can-open="$store.AppStore.reportIngredients"
      @generate="generateReportIngredients"
      @open="openGoogleSheetIngredients"
      class="block"
    />
    <GoogleSheetGenerate
      title="Recipes"
      :loading="loadingReportRecipes"
      :can-open="$store.AppStore.reportRecipes"
      @generate="generateReportRecipes"
      @open="openGoogleSheetRecipes"
      class="block"
    />
    <GoogleSheetGenerate
      title="Promocodes"
      :loading="loadingReportPromocodes"
      :can-open="$store.AppStore.reportPromocodes"
      @generate="generateReportPromocodes"
      @open="openGoogleSheetPromocodes"
      class="block"
    />
    <GoogleSheetGenerate
      title="Users Payments"
      :loading="loadingReportUsersPayments"
      :can-open="$store.AppStore.reportUsersPayments"
      @generate="generateReportUsersPayments"
      @open="openGoogleSheetUsersPayments"
      class="block"
    />
  </v-col>
</template>

<script>
import GoogleSheetGenerate from "@/components/GoogleSheetGenerate";
export default {
  name: 'Report',
  components: {GoogleSheetGenerate},
  data: () => ({
    spreadSheetId: '1WQYRwopvnt0_y9mqc-xdrF0me3YfkUwPG89tENV0z-k',
    loadingReportIngredients: false,
    loadingReportRecipes: false,
    loadingReportPromocodes: false,
    loadingReportUsersPayments: false,
  }),
  methods: {
    async generateReportIngredients() {
      this.loadingReportIngredients = true;
      try {
        await this.$api.main.generateReportIngredients();
      } finally {
        this.loadingReportIngredients = false;
      }
    },
    async generateReportRecipes() {
      this.loadingReportRecipes = true;
      try {
        await this.$api.main.generateReportRecipes();
      } finally {
        this.loadingReportRecipes = false;
      }
    },
    async generateReportPromocodes() {
      this.loadingReportPromocodes = true;
      try {
        await this.$api.main.generateReportPromocodes();
      } finally {
        this.loadingReportPromocodes = false;
      }
    },
    async generateReportUsersPayments() {
      this.loadingReportUsersPayments = true;
      try {
        await this.$api.main.generateReportUsersPayments();
      } finally {
        this.loadingReportUsersPayments = false;
      }
    },
    openGoogleSheetIngredients() {
      this.openGoogleSheet(this.$store.AppStore.reportIngredients);
    },
    openGoogleSheetRecipes() {
      this.openGoogleSheet(this.$store.AppStore.reportRecipes);
    },
    openGoogleSheetPromocodes() {
      this.openGoogleSheet(this.$store.AppStore.reportPromocodes);
    },
    openGoogleSheetUsersPayments() {
      this.openGoogleSheet(this.$store.AppStore.reportUsersPayments);
    },
    openGoogleSheet(sheetId) {
      window.open(`https://docs.google.com/spreadsheets/d/${this.spreadSheetId}/edit#gid=${sheetId || 0}`, '_blank');
    },
  },
};
</script>

<style scoped>
.block {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
