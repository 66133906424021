<template>
  <div>
    <v-btn depressed
           class="ma-1"
           @click="$emit('generate')"
           :loading="loading"
    >
      {{ title }}
    </v-btn>
    <v-btn v-if="canOpen"
           class="ma-1"
           depressed
           @click="$emit('open')"
    >
      Open google sheet
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'GoogleSheetGenerate',
  props: {
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    canOpen: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style scoped>

</style>
